<template>
  <div class="info" id="watermark">
    <top-bar :title="'沿街店铺详情'" :left="true"></top-bar>

    <van-popup v-model="typeShow" position="bottom" >
      <van-cascader
        :field-names="fieldNames"
        v-model="cascaderValue"
        title="请选择"
        :options="list.placeTypeList"
        @close="typeShow = false"
        @change="typeConfirm"
      />
    </van-popup>
    <van-popup
        v-model="operateStatusShow"
        position="bottom">
      <van-picker
          v-model="operateStatusShow"
          title="请选择"
          show-toolbar
          value-key="label"
          :columns="list.operateStatusList"
          @cancel="operateStatusShow = false"
          @confirm="operateStatusConfirm"
      />
    </van-popup>
    <van-popup
      v-model="subareaShow"
      position="bottom">
      <van-picker
        title="请选择"
        show-toolbar
        :columns="list.orgList"
        value-key="label"
        @confirm="subareaConfirm"
        @cancel="subareaShow = false"
      />
    </van-popup>

    <van-popup
        v-model="buildingShow"
        position="bottom">
      <van-picker
          title="请选择"
          show-toolbar
          :columns="list.publicBuildingList"
          value-key="label"
          @confirm="buildingConfirm"
          @cancel="buildingShow = false"
      />
    </van-popup>

    <van-popup v-model="categoryShow" position="bottom" >
      <van-picker
          title="请选择"
          show-toolbar
          :columns="list.categoryList"
          value-key="label"
          @confirm="categoryConfirm"
          @cancel="categoryShow = false"
      />
    </van-popup>

    <van-popup
        v-model="importantTypeShow"
        position="bottom">
      <van-picker
          v-model="importantTypeShow"
          title="请选择"
          show-toolbar
          value-key="label"
          :columns="list.importantTypeList"
          @cancel="importantTypeShow = false"
          @confirm="importantTypeConfirm"
      />
    </van-popup>

    <van-popup
        v-model="safetyPerilShow"
        position="bottom">
      <van-picker
          v-model="safetyPerilShow"
          title="请选择"
          show-toolbar
          value-key="label"
          :columns="list.safetyPerilList"
          @cancel="safetyPerilShow = false"
          @confirm="safetyPerilConfirm"
      />
    </van-popup>

    <van-popup
        v-model="hasHygieneShow"
        position="bottom">
      <van-picker
          v-model="hasHygieneShow"
          title="请选择"
          show-toolbar
          value-key="label"
          :columns="list.hasFoodList"
          @cancel="hasHygieneShow = false"
          @confirm="hasHygieneConfirm"
      />
    </van-popup>

    <van-popup
        v-model="hasFoodShow"
        position="bottom">
      <van-picker
          v-model="hasFoodShow"
          title="请选择"
          show-toolbar
          value-key="label"
          :columns="list.hasFoodList"
          @cancel="hasFoodShow = false"
          @confirm="hasFoodConfirm"
      />
    </van-popup>

    <van-popup
        v-model="hasMedicalShow"
        position="bottom">
      <van-picker
          v-model="hasMedicalShow"
          title="请选择"
          show-toolbar
          value-key="label"
          :columns="list.hasMedicalList"
          @cancel="hasMedicalShow = false"
          @confirm="hasMedicalConfirm"
      />
    </van-popup>

    <van-popup
        v-model="hasDrugShow"
        position="bottom">
      <van-picker
          v-model="hasDrugShow"
          title="请选择"
          show-toolbar
          value-key="label"
          :columns="list.hasDrugList"
          @cancel="hasDrugShow = false"
          @confirm="hasDrugConfirm"
      />
    </van-popup>

    <van-popup v-model="operateBeginShow" position="bottom"  >
      <van-datetime-picker v-model="selectMsg.operateBeginTime" @confirm="operateBeginConfim" @cancel="operateBeginShow = false"  type="date" title="选择年月日"/>
    </van-popup>

    <van-popup v-model="operateEndShow" position="bottom"  >
      <van-datetime-picker v-model="selectMsg.operateEndTime" @confirm="operateEndConfim" @cancel="operateEndShow = false"  type="date" title="选择年月日"/>
    </van-popup>

    <van-popup v-model="registerShow" position="bottom"  >
      <van-datetime-picker v-model="selectMsg.registerTime" @confirm="registerConfim" @cancel="registerShow = false"  type="date" title="选择年月日"/>
    </van-popup>

    <van-popup
        v-model="moneySourceShow"
        position="bottom">
      <van-picker
          v-model="moneySourceShow"
          title="请选择"
          show-toolbar
          value-key="label"
          :columns="list.moneySourceList"
          @cancel="moneySourceShow = false"
          @confirm="moneySourceConfirm"
      />
    </van-popup>

    <van-popup v-model="payCheckShow" position="bottom"  >
      <van-datetime-picker v-model="selectMsg.payCheck" @confirm="payCheckConfim" @cancel="payCheckShow = false"  type="date" title="选择年月日"/>
    </van-popup>

    <van-popup
        v-model="payerStatusShow"
        position="bottom">
      <van-picker
          v-model="payerStatusShow"
          title="请选择"
          show-toolbar
          value-key="label"
          :columns="list.payerStatusList"
          @cancel="payerStatusShow = false"
          @confirm="payerStatusConfirm"
      />
    </van-popup>

    <van-popup v-model="payCancelShow" position="bottom"  >
      <van-datetime-picker v-model="selectMsg.payCancel" @confirm="payCancelConfim" @cancel="payCancelShow = false"  type="date" title="选择年月日"/>
    </van-popup>

    <van-popup v-model="signCancelShow" position="bottom"  >
      <van-datetime-picker v-model="selectMsg.signCancelTime" @confirm="signCancelConfim" @cancel="signCancelShow = false"  type="date" title="选择年月日"/>
    </van-popup>

    <van-popup
        v-model="riskLevelShow"
        position="bottom">
      <van-picker
          v-model="riskLevelShow"
          title="请选择"
          show-toolbar
          value-key="label"
          :columns="list.riskLevelList"
          @cancel="riskLevelShow = false"
          @confirm="riskLevelConfirm"
      />
    </van-popup>

    <van-popup v-model="insuredStageShow" position="bottom"  >
      <van-datetime-picker v-model="selectMsg.insuredStage" @confirm="insuredStageConfim" @cancel="insuredStageShow = false"  type="date" title="选择年月日"/>
    </van-popup>

    <van-popup
        v-model="insuredStatusShow"
        position="bottom">
      <van-picker
          v-model="insuredStatusShow"
          title="请选择"
          show-toolbar
          value-key="label"
          :columns="list.insuredStatusList"
          @cancel="insuredStatusShow = false"
          @confirm="insuredStatusConfirm"
      />
    </van-popup>

    <!-- 选择地址 -->
    <van-popup v-model="buildingShow" position="bottom" @click-overlay="buildingOverlay">
      <van-cascader v-model="dataForm.building" title="请选择" :options="list.buildingList"
                    @close="buildingShow = false" @change="buildingFinish" :field-names="fieldNames"/>
    </van-popup>

    <van-popup
        v-model="codeShow"
        position="bottom">
      <van-picker
          title="请选择"
          show-toolbar
          :columns="list.codeList"
          value-key="label"
          @confirm="codeConfirm"
          @cancel="codeShow = false"
      />
    </van-popup>

    <div v-show="!gridShow">
    <van-cell-group>
      <van-cell>
        <template #title>
          <div class="blue-box"></div>
          <span class="form-title-view">基本信息</span>
        </template>
      </van-cell>
      <van-cell title="所属机构" :value="selectMsg.subareaStr" @click="disable ? (subareaShow = false) : (subareaShow = true)"
                :value-class="{'value-common':selectMsg.subareaStr=='请选择'}" :is-link="!disable" required/>
      <van-cell title="所属楼幢房屋" :value="selectMsg.buildingStr" @click="disable ? (buildingShow = false) : (buildingShow = true)"
                :value-class="{'value-common':selectMsg.buildingStr=='请选择'}" :is-link="!disable"/>
      <van-cell title="常用名称" required>
        <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.name" placeholder="请输入" style="text-align: right"/>
      </van-cell>
      <van-cell title="营业执照名称">
        <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.licenseName" placeholder="请输入" style="text-align: right"/>
      </van-cell>
      <van-cell title="统一社会信用代码" class="licenseCode">
        <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.licenseCode" @blur="getInfoByLicenseCode" laceholder="请输入" style="text-align: right">
          <template #right-icon>
            <van-uploader :after-read="cardIdIdentified">
              <van-icon size="25" color="#BBB" name="photograph"/>
            </van-uploader>
          </template>
        </van-field>
      </van-cell>
      <van-cell title="经营类型" :value="selectMsg.placeTypeStr" @click="disable ? (typeShow = false) : (typeShow = true)"
                :value-class="{'value-common':selectMsg.placeTypeStr=='请选择'}" :is-link="!disable" required/>
      <van-cell title="店铺类型" :value="selectMsg.categoryStr" @click="disable ? (categoryShow = false) : (categoryShow = true)"
                :value-class="{'value-common':selectMsg.categoryStr=='请选择'}" :is-link="!disable" required/>
      <van-cell title="面积(/m²)">
        <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.areas" placeholder="请输入" style="text-align: right"/>
      </van-cell>
      <van-cell title="职工人数">
        <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.staffNum" placeholder="请输入" type="digit" style="text-align: right"/>
      </van-cell>
      <van-cell title="主管单位">
        <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.competentUnit" placeholder="请输入" style="text-align: right"/>
      </van-cell>
      <van-cell title="所属网格" :value="selectMsg.gridName || '请选择'" @click="disable ? (gridShow = false) : (gridShow = true)"
                :value-class="{'value-common':selectMsg.gridName=='请选择'}" :is-link="!disable"/>
      <van-cell title="经营范围" :border="false" class="cellClass" required />
      <van-field type="textarea" clearable clear-trigger="always" :readonly="disable" v-model="dataForm.content" placeholder="请输入" style="text-align: right" rows="1" autosize/>
      <van-cell title="经营状态" :value="selectMsg.operateStatusStr" @click="disable ? (operateStatusShow = false) : (operateStatusShow = true)"
                :value-class="{'value-common':selectMsg.operateStatusStr=='请选择'}" :is-link="!disable"/>
      <van-cell
          readonly
          clickable
          name="店铺标签"
          :value-class="{'value-common':selectMsg.labelStr=='请选择'}"
          :value="selectMsg.labelStr"
          title="店铺标签"
          :is-link="!disable"
          @click="showlabel"
      />

      <van-popup v-model="label" position="bottom" @click-overlay="labelOverlay">
        <van-cascader
            title="请选择"
            value="value"
            :field-names="{ text: 'label', value: 'value', children: 'children' }"
            :options="list.userCommunityLabelTree"
            active-color="#1989fa"
            @close="labelClose"
            @change="labelFinish"
        />
      </van-popup>

      <van-cell hover-class="none" v-if="labels.length>0">
        <template v-for="item in labels">
          <div class="vanTag" v-if="item.rightShow && !item.delete" :key="item.id" >
            <span :style="disable ? 'border: none' : ''">{{ item.name }}</span>
            <van-icon style="padding: 0 10px" name="cross" v-if="!disable" @click="removeLabel(item.value)"/>
          </div>
        </template>
      </van-cell>

      <template v-if="isCodeShow">
        <van-cell title="标准地址名称">
          <van-field clearable type="textarea" autosize rows="1" clear-trigger="always" :readonly="disable" v-model="selectMsg.codeName"
                     @blur="dataForm.address = dataForm.codeName" placeholder="请输入" style="text-align: right"/>
        </van-cell>
        <p v-if="isTipShow" style="line-height: 0.3rem;margin: 0.1rem 0 0 0.5rem;color: #F56C6C;font-size: 14px;">{{tipValue}}</p>
        <van-cell title="标准地址码" :value="selectMsg.codeStr || '请选择'" @click="disable ? false : getCode()"
                  :value-class="{'value-common':selectMsg.codeStr=='请选择'}" :is-link="!disable"/>
      </template>
      <van-cell title="详细地址" :border="false" class="cellClass" required />
      <van-field type="textarea" clearable clear-trigger="always" :readonly="disable" v-model="dataForm.address" placeholder="请输入" style="text-align: right" rows="1" autosize/>
      <van-cell title="备注" :border="false" class="cellClass"/>
      <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.remark" placeholder="请输入" style="text-align: right"/>
      <van-row class="uploadBox">
        <van-col :span="24">
          <van-uploader
              v-model="urls"
              :readonly="disable"
              @delete="uploaddel"
              :after-read="uploadRead"
              :deletable="!disable"
              :show-upload="!disable"
          />
        </van-col>
      </van-row>
    </van-cell-group>

      <!-- 自定义字段 -->
      <field-view ref="fieldView" :disabled="disable" :id="dataForm.id" />

    <van-cell-group class="info-second">
      <van-cell>
        <template #title>
          <div class="blue-box"></div>
          <span class="form-title-view">联络信息</span>
          <div style="color:#387FF5;float:right" @click="firstVisible=!firstVisible"><van-icon :name="firstVisible?'arrow-up':'arrow-down'"></van-icon></div>
        </template>
      </van-cell>
      <div v-show="firstVisible">
        <van-cell title="主要联系人" required>
          <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.contact" placeholder="请输入" style="text-align: right"/>
        </van-cell>
        <van-cell title="联系人电话" required>
          <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.contactMobile" placeholder="请输入" style="text-align: right"/>
        </van-cell>
        <van-cell title="法人/负责人">
          <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.legal" placeholder="请输入" style="text-align: right"/>
        </van-cell>
        <van-cell title="法人/负责人电话">
          <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.legalMobile" placeholder="请输入" style="text-align: right"/>
        </van-cell>
      </div>
    </van-cell-group>

      <van-cell-group class="info-second">
        <van-cell>
          <template #title>
            <div class="blue-box"></div>
            <span class="form-title-view">安全信息</span>
            <div style="color:#387FF5;float:right" @click="sevenVisible=!sevenVisible"><van-icon :name="sevenVisible?'arrow-up':'arrow-down'"></van-icon></div>
          </template>
        </van-cell>
        <div v-show="sevenVisible">
          <van-cell title="安全隐患类型" :value="selectMsg.safetyPerilStr || '请选择'" @click="disable ? (safetyPerilShow = false) : (safetyPerilShow = true)"
                    :value-class="{'value-common':selectMsg.safetyPerilStr=='请选择'}" :is-link="!disable"/>
          <van-cell title="重点属性" :value="selectMsg.importantTypeStr" @click="disable ? (importantTypeShow = false) : (importantTypeShow = true)"
                    :value-class="{'value-common':selectMsg.importantTypeStr=='请选择'}" :is-link="!disable" required/>
          <van-cell title="灭火器数量">
            <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.fireNum" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <van-cell title="煤气瓶数量">
            <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.gasNum" placeholder="请输入" style="text-align: right"/>
          </van-cell>
        </div>
      </van-cell-group>

      <van-cell-group class="info-second">
        <van-cell>
          <template #title>
            <div class="blue-box"></div>
            <span class="form-title-view">许可证信息</span>
            <div style="color:#387FF5;float:right" @click="eightVisible=!eightVisible"><van-icon :name="eightVisible?'arrow-up':'arrow-down'"></van-icon></div>
          </template>
        </van-cell>
        <div v-show="eightVisible">
          <van-cell title="是否有卫生许可证" :value="selectMsg.hasHygieneStr || '请选择'" @click="disable ? (hasHygieneShow = false) : (hasHygieneShow = true)"
                    :value-class="{'value-common':selectMsg.hasHygieneStr=='请选择'}" :is-link="!disable"/>
          <van-cell title="卫生许可证号码" v-if="dataForm.hasHygiene == 0">
            <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.hygieneCode" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <van-cell title="是否有食品经营许可证" :value="selectMsg.hasFoodStr || '请选择'" @click="disable ? (hasFoodShow = false) : (hasFoodShow = true)"
                    :value-class="{'value-common':selectMsg.hasFoodStr=='请选择'}" :is-link="!disable"/>
          <van-cell title="是否有医疗器械经营许可证" :value="selectMsg.hasMedicalStr || '请选择'" @click="disable ? (hasMedicalShow = false) : (hasMedicalShow = true)"
                    :value-class="{'value-common':selectMsg.hasMedicalStr=='请选择'}" :is-link="!disable"/>
          <van-cell title="是否有药品经营许可证" :value="selectMsg.hasDrugStr || '请选择'" @click="disable ? (hasDrugShow = false) : (hasDrugShow = true)"
                    :value-class="{'value-common':selectMsg.hasDrugStr=='请选择'}" :is-link="!disable"/>
        </div>
      </van-cell-group>

    <van-cell-group class="info-second">
      <van-cell>
        <template #title>
          <div class="blue-box"></div>
          <span class="form-title-view">注册登记信息</span>
          <div style="color:#387FF5;float:right" @click="secondVisible=!secondVisible"><van-icon :name="secondVisible?'arrow-up':'arrow-down'"></van-icon></div>
        </template>
      </van-cell>
      <div v-show="secondVisible">
        <van-cell title="注册地址" :border="false" class="cellClass" />
        <van-field type="textarea" clearable clear-trigger="always" :readonly="disable" v-model="extraInfo.address" placeholder="请输入" style="text-align: right" rows="1" autosize/>
        <van-cell title="开办资金">
          <van-field clearable clear-trigger="always" :readonly="disable" v-model="extraInfo.amount" placeholder="请输入" type="digit" style="text-align: right"/>
        </van-cell>
        <van-cell title="经营（驻在）开始期限" :value="extraInfo.operateBeginTime || '请选择'" @click="operateBeginShow = !operateBeginShow"
                  :value-class="{'value-common':!extraInfo.operateBeginTime}" is-link />
        <van-cell title="经营（驻在）结束期限" :value="extraInfo.operateEndTime || '请选择'" @click="operateEndShow = !operateEndShow"
                  :value-class="{'value-common':!extraInfo.operateEndTime}" is-link />
        <van-cell title="注册（登记）日期" :value="extraInfo.registerTime || '请选择'" @click="registerShow = !registerShow"
                  :value-class="{'value-common':!extraInfo.registerTime}" is-link />
        <van-cell title="注册登记号">
          <van-field clearable clear-trigger="always" :readonly="disable" v-model="extraInfo.registerCode" placeholder="请输入" style="text-align: right"/>
        </van-cell>
        <van-cell title="经济来源" :value="selectMsg.moneySourceStr || '请选择'" @click="moneySourceShow = !moneySourceShow"
                  :value-class="{'value-common':selectMsg.moneySourceStr=='请选择'}" is-link />
        <van-cell title="登记机关名称">
          <van-field clearable clear-trigger="always" :readonly="disable" v-model="extraInfo.officeName" placeholder="请输入" style="text-align: right"/>
        </van-cell>
      </div>
    </van-cell-group>

      <van-cell-group class="info-second">
        <van-cell>
          <template #title>
            <div class="blue-box"></div>
            <span class="form-title-view">税务信息</span>
            <div style="color:#387FF5;float:right" @click="thirdVisible=!thirdVisible"><van-icon :name="thirdVisible?'arrow-up':'arrow-down'"></van-icon></div>
          </template>
        </van-cell>
        <div v-show="thirdVisible">
          <van-cell title="纳税人名称">
            <van-field clearable clear-trigger="always" :readonly="disable" v-model="extraInfo.payer" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <van-cell title="纳税人识别号">
            <van-field clearable clear-trigger="always" :readonly="disable" v-model="extraInfo.payerCode" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <van-cell title="税务登记日期" :value="extraInfo.payCheck || '请选择'" @click="payCheckShow = !payCheckShow"
                    :value-class="{'value-common':!extraInfo.payCheck}" is-link />
          <van-cell title="税务登记机关">
            <van-field clearable clear-trigger="always" :readonly="disable" v-model="extraInfo.payOffice" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <van-cell title="纳税人状态" :value="selectMsg.payerStatusStr || '请选择'" @click="payerStatusShow = !payerStatusShow"
                    :value-class="{'value-common':selectMsg.payerStatusStr=='请选择'}" is-link />
          <van-cell title="税务注销日期" :value="extraInfo.payCancel || '请选择'" @click="payCancelShow = !payCancelShow"
                    :value-class="{'value-common':!extraInfo.payCancel}" is-link />
        </div>
      </van-cell-group>

      <van-cell-group class="info-second">
        <van-cell>
          <template #title>
            <div class="blue-box"></div>
            <span class="form-title-view">注销登记信息</span>
            <div style="color:#387FF5;float:right" @click="fourthVisible=!fourthVisible"><van-icon :name="fourthVisible?'arrow-up':'arrow-down'"></van-icon></div>
          </template>
        </van-cell>
        <div v-show="fourthVisible">
          <van-cell title="注销登记日期" :value="extraInfo.signCancelTime || '请选择'" @click="signCancelShow = !signCancelShow"
                    :value-class="{'value-common':!extraInfo.signCancelTime}" is-link />
          <van-cell title="注销/吊销原因" :border="false" class="cellClass" />
          <van-field type="textarea" clearable clear-trigger="always" :readonly="disable" v-model="extraInfo.signCancelReason" placeholder="请输入" style="text-align: right" rows="1" autosize/>
        </div>
      </van-cell-group>

      <van-cell-group class="info-second">
        <van-cell>
          <template #title>
            <div class="blue-box"></div>
            <span class="form-title-view">参保信息</span>
            <div style="color:#387FF5;float:right" @click="fiveVisible=!fiveVisible"><van-icon :name="fiveVisible?'arrow-up':'arrow-down'"></van-icon></div>
          </template>
        </van-cell>
        <div v-show="fiveVisible">
          <van-cell title="参保单位名称">
            <van-field clearable clear-trigger="always" :readonly="disable" v-model="extraInfo.insuredName" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <van-cell title="参保单位编号">
            <van-field clearable clear-trigger="always" :readonly="disable" v-model="extraInfo.insuredCode" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <van-cell title="社保行业风险类别" :value="selectMsg.riskLevel || '请选择'" @click="riskLevelShow = !riskLevelShow"
                    :value-class="{'value-common':selectMsg.riskLevel=='请选择'}" is-link />
          <van-cell title="参保缴费期别" :value="extraInfo.insuredStage || '请选择'" @click="insuredStageShow = !insuredStageShow"
                    :value-class="{'value-common':!extraInfo.insuredStage}" is-link />
          <van-cell title="参保缴费状态" :value="selectMsg.insuredStatus || '请选择'" @click="insuredStatusShow = !insuredStatusShow"
                    :value-class="{'value-common':selectMsg.insuredStatus=='请选择'}" is-link />
          <van-cell title="保险管理机关">
            <van-field clearable clear-trigger="always" :readonly="disable" v-model="extraInfo.manageOffice" placeholder="请输入" style="text-align: right"/>
          </van-cell>
        </div>
      </van-cell-group>

      <van-cell-group class="info-second">
        <van-cell>
          <template #title>
            <div class="blue-box"></div>
            <span class="form-title-view">变更信息</span>
            <div style="color:#387FF5;float: right" @click="sixVisible=!sixVisible"><van-icon :name="sixVisible?'arrow-up':'arrow-down'"></van-icon></div>
          </template>
        </van-cell>
        <div v-show="sixVisible">
          <div v-for="(item,index) in changeList" :key="index">
            <van-cell>
              <template #title>
                <span class="form-title-view secondTitle">变更信息{{index + 1}}</span>
                <div class="button-right">
                  <van-button plain size="small" :disabled="changeList.length == 1" @click="deleteClick(item.id, index)" style="background-color:transparent; color: #E9564B;border: 1px solid #E9564B;margin-right: 20px;">删除</van-button>
                  <van-button plain size="small" v-if="index == 0" @click="addInfo">新增</van-button>
                </div>
              </template>
            </van-cell>
            <van-cell title="变更日期" :value="item.changeDate || '请选择'" @click="item.changeDateShow = !item.changeDateShow"
                      :value-class="{'value-common':!item.changeDate}" is-link />
            <van-popup v-model="item.changeDateShow" position="bottom"  >
              <van-datetime-picker @confirm="changeDateConfim($event, index)" @cancel="item.changeDateShow = false"  type="date" title="选择年月日"/>
            </van-popup>
            <van-cell title="变更事项">
              <van-field clearable clear-trigger="always" v-model="item.changeReason" placeholder="请输入" style="text-align: right"/>
            </van-cell>
            <van-cell title="变更前内容" :border="false" class="cellClass" />
            <van-field type="textarea" clearable clear-trigger="always" v-model="item.beforeContent" placeholder="请输入" style="text-align: right" rows="1" autosize/>
            <van-cell title="变更后内容" :border="false" class="cellClass" />
            <van-field type="textarea" clearable clear-trigger="always" v-model="item.afterContent" placeholder="请输入" style="text-align: right" rows="1" autosize/>
          </div>
        </div>
      </van-cell-group>

    <van-row class="btns">
      <van-col :span="24" v-if="!disable">
        <van-button type="info" size="large" round @click="submit" :loading="buttonLoading">完成</van-button>
      </van-col>
      <van-row gutter="10" v-else>
        <van-col :span="12">
          <van-button plain type="danger" size="large" round @click="deleteClick" style="background-color:transparent">删除</van-button>
        </van-col>
        <van-col :span="12">
          <van-button type="info" size="large" round @click="disable=!disable">编辑</van-button>
        </van-col>
      </van-row>
    </van-row>
    </div>

    <grid-select v-show="gridShow" :list="list.gridList" @selectLabel="selectGrid" :isArray="1" ref="gridSelect"></grid-select>
  </div>
</template>
<script>
import topBar from "@/components/topBar/topBar";
import { getbelongGrid, getDictTree, getbelongSubArea, getVirtualDict, getMiniGrid,listComRightLabel } from "@/utils/common";
import fieldView from '../../../components/field-view/field-view.vue';
import { getImageStream, formatLabel } from "@/utils/index";
import { isNumber } from "@/utils/validate";
import GridSelect from '../../userRes/grid-select.vue'
import {formatterDate} from '@/utils/utils'
import HouseSelect from "../../../components/houseSelect/index.vue";
import {Dialog} from "vant";
import Vue from "vue";

let that
export default {
  components: {
    topBar,
    fieldView,
    GridSelect,
    HouseSelect
  },
  created() {
    this.$watermark.set(this.$globalData.userInfo.userName, this.$globalData.userInfo.mobile)
    if (this.$route.query.id) {
      (this.disable = true), (this.dataForm.id = this.$route.query.id);
    }
    this.street = this.$globalData.userInfo.street
    this.initOrgId = this.$globalData.userInfo.orgId
    this.isCodeShow = this.$globalData.userInfo.codeType == 2 ? true : false
    that = this;
    getbelongSubArea(this.$orgId, function (e) {
      that.list.orgList = that.list.orgList.concat(e);
    });
    getbelongGrid({ orgId: this.$orgId }, function (e) {
      that.list.gridList = that.list.gridList.concat(e);
    });
    getDictTree({ code: "streetShopType" }, function (e) {
      that.list.placeTypeList = that.list.placeTypeList.concat(e);
    });
    getMiniGrid({community:this.$orgId}, function (list) {
      that.list.gridList = list
    })
    getVirtualDict('importantType', function (e) {
      that.list.importantTypeList = e
    })
    getVirtualDict('safetyPeril', function (e) {
      that.list.safetyPerilList = e
    })
    listComRightLabel(function (labels) {
      that.list.userCommunityLabelTree = labels
    }, 4)
    getVirtualDict('riskType', function (e) {
      that.list.riskLevelList = e
    })
    getVirtualDict('shopOperateStatus', function (e) {
      that.list.operateStatusList = e
    })
  },
  data() {
    return {
      urlCY:'https://h5.app.hzuht.com/commonApp',
      depth: parseInt(this.$globalData.userInfo.depth),
      operateStatusShow: false,
      firstVisible: true,
      secondVisible: false,
      thirdVisible: false,
      fourthVisible: false,
      fiveVisible: false,
      sixVisible: false,
      sevenVisible: false,
      eightVisible: false,
      buttonLoading: false,
      fieldNames: { text: "label", value: "value", children: "children" },
      cascaderValue: [],
      disable: false,
      typeShow: false,
      houseTypeShow: false,
      importantShow: false,
      importantTypeShow: false,
      trinityShow: false,
      categoryShow: false,
      subareaShow: false,
      buildingShow: false,
      hasLicenseShow: false,
      safetyPerilShow: false,
      scaleShow: false,
      hazardousShow: false,
      gridShow: false,
      hasHygieneShow: false,
      hasFoodShow: false,
      hasMedicalShow: false,
      hasDrugShow: false,
      operateBeginShow: false,
      operateEndShow: false,
      registerShow: false,
      moneySourceShow: false,
      payCheckShow: false,
      payerStatusShow: false,
      payCancelShow: false,
      signCancelShow: false,
      riskLevelShow: false,
      insuredStageShow: false,
      insuredStatusShow: false,
      codeShow: false,
      isCodeShow: false,
      isTipShow: false,
      tipValue: '',
      fileList: [],
      street: 0,
      initOrgId: 0,
      codeMappingId: 0,
      dataForm: {
        shopOperateStatus: '',
        id: "",
        name: "",
        licenseName: "",
        type: "",
        contact: "",
        contactMobile: "",
        legal: "",
        legalMobile: "",
        address: "",
        community: "",
        subarea: "",
        buildingId: "1",
        licenseCode: "",
        content: '',
        imgUrl: "",
        newFileMessages: [],
        deleteFiles: [],
        gridId: 0,
        miniGridId: 0,
        category: '',
        areas: '',
        staffNum: '',
        labels: [],
        importantType: '',
        safetyPeril: '',
        competentUnit: '',
        hygieneCode: '',
        gasNum: '',
        fireNum: '',
        hasHygiene: 1,
        hasFood: 1,
        hasDrug: 1,
        hasMedical: 1,
        code: '',
        buildingDepth: 0
      },
      extraInfo: {
        address: '', // 注册地址
        amount: '', // 开办资金
        operateBeginTime: '',// 经营开始期限
        operateEndTime: '',// 经营结束期限
        registerTime: '', // 注册日期
        registerCode: '', // 注册登记号
        moneySource: '', // 经济来源
        officeName: '', // 登记机关名称
        payer: '', // 纳税人名称
        payerCode: '', // 纳税人识别号
        payCheck: '', // 税务登记日期
        payOffice: '', // 税务登记机关
        payerStatus: '', // 纳税人状态
        payCancel: '', // 税务注销日期
        insuredName: '', // 参保单位名称
        insuredCode: '', // 参保单位编号
        riskLevel: '', // 社保行业风险类别
        insuredStage: '', // 参保缴费数据期别
        insuredStatus: '', // 参保缴费状态
        manageOffice: '', // 保险管理机关
        signCancelTime: '', // 注销登记日期
        signCancelReason: '', // 注销/吊销原因
      },
      label: false,
      labels: [],
      temporaryLabel: null,
      temporaryBuilding: null,
      urls: [],
      selectMsg: {
        operateStatusStr: '请选择',
        placeTypeStr: "请选择",
        communityStr: "未知",
        subareaStr: "请选择",
        buildingStr: "无",
        hasLicenseStr: "否",
        gridName: '请选择',
        categoryStr: '请选择',
        importantTypeStr: '请选择',
        labelStr: '请选择',
        safetyPerilStr: '请选择',
        hasFoodStr: '否',
        hasMedicalStr: '否',
        hasDrugStr: '否',
        operateBeginTime: '',
        operateEndTime: '',
        registerTime: '',
        moneySourceStr: '请选择',
        payCheck: '',
        payerStatusStr: '请选择',
        payCancel: '',
        signCancelTime: '',
        riskLevel: '请选择',
        insuredStage: '',
        insuredStatus: '请选择',
        codeStr: '请选择',
        codeName: '',
        hasHygieneStr: '请选择'
      },
      list: {
        operateStatusList: [],
        orgList: [],
        gridList: [],
        placeTypeList: [],
        publicBuildingList: [],
        categoryList: [{value: 2, label: '企业'}, {value: 1, label: '个体工商户'}],
        importantTypeList: [],
        safetyPerilList: [],
        hasFoodList: [
          {value: 0, label: '是'},
          {value: 1, label: '否'}
        ],
        hasMedicalList: [
          {value: 0, label: '是'},
          {value: 1, label: '否'}
        ],
        hasDrugList: [
          {value: 0, label: '是'},
          {value: 1, label: '否'}
        ],
        moneySourceList: [{value: 1, label: '自有资金'}, {value: 2, label: '银行借贷资金'}, {value: 3, label: '预售款资金'}],
        payerStatusList: [{value: 1, label: '正常'}, {value: 0, label: '非正常'}], // 纳税人状态下拉
        riskLevelList: [], // 社保行业风险类别下拉
        insuredStatusList: [{value: 1, label: '参保缴费'}, {value: 2, label: '暂停缴费'}, {value: 3, label: '冻结'}], // 参保缴费状态下拉
        buildingList: [],
        codeList: []
      },
      changeList: [{
        changeDate: '',
        changeReason: '',
        beforeContent: '',
        afterContent: '',
        status: 0,
        changeDateShow: false
      }], //变更信息
      submitList: []
    };
  },
  methods: {
    // 获取房屋列表
    getBuildingList(orgId) {
      if (this.street == this.initOrgId) {
        this.$http({
          url: this.$http.adornUrl('/wxapp/building/house/liveWhichTree'),
          method: 'get',
          params: this.$http.adornParams({
            communityId: orgId,
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.list.buildingList = data.liveWhichTree
          } else {
            this.$toast.fail(data.msg)
          }
        })
      } else {
        this.$http({
          url: this.$http.adornUrl('/wxapp/building/house/bySubareaIdTree'),
          method: 'get',
          params: this.$http.adornParams({
            subareaId: orgId,
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.list.buildingList = data.liveWhichTree
          } else {
            this.$toast.fail(data.msg)
          }
        })
      }
    },
    // 根据营业执照号获取店铺信息
    getInfoByLicenseCode() {
      if (this.dataForm.licenseCode) {
        this.$http({
          url: this.$http.adornUrl(`/wxapp/place/placeinfostreetshop/getByLicenseCode`),
          method: 'get',
          params: this.$http.adornParams({
            'licenseCode': this.dataForm.licenseCode,
            'isOver': 1
          })
        }).then(({data}) => {
          if (data && data.code === 0 && data.placeInfoStreetShop) {
            this.$dialog.confirm({
              title: '提示',
              message: '统一社会信用代码已存在请选择操作',
              confirmButtonText: '导入',
              cancelButtonText: '取消'
            }).then(() => {
              this.setInfoToForm(data.placeInfoStreetShop)
            })
          }
        })
      }
    },
    // 设置数据信息到控件
    setInfoToForm (data) {
      if (data.subarea) {
        this.dataForm.community = parseInt(data.community)
        this.dataForm.subarea = data.subarea
        this.dataForm.orgId = data.subarea
      } else if (data.community) {
        this.dataForm.community = parseInt(data.community)
        this.dataForm.orgId = parseInt(data.community)
      } else {
        this.dataForm.street = data.street
        this.dataForm.orgId = data.street
      }
      this.dataForm.shopOperateStatus=data.shopOperateStatus
      this.dataForm.id = data.id
      this.dataForm.type = data.type
      this.dataForm.staffNum = data.staffNum || 0
      this.dataForm.name = data.name
      this.dataForm.licenseName = data.onlineName
      this.dataForm.category = data.shopType
      // 管理网格
      if (data.gridId && !data.miniGridId) {
        this.dataForm.gridId = data.gridId
        this.dataForm.grid = data.gridId
      }

      if (data.miniGridId) {
        this.dataForm.gridId = data.gridId
        this.dataForm.miniGridId = data.miniGridId
        this.dataForm.grid = [data.gridId, data.miniGridId]
      }
      if (data.houseType == null || data.houseType === undefined) {
        this.dataForm.houseType = -1
      } else {
        this.dataForm.houseType = data.houseType
      }
      this.dataForm.buildingId = data.buildingId
      this.dataForm.contact = data.contact
      this.dataForm.contactMobile = data.contactMobile
      this.dataForm.legal = data.legal
      this.dataForm.legalMobile = data.legalMobile
      this.dataForm.hasLicense = data.hasLicense
      this.dataForm.hasFood = data.hasFood
      this.dataForm.hasDrug = data.hasDrug
      this.dataForm.hasMedical = data.hasMedical
      this.dataForm.medicalCode = data.medicalCode
      this.dataForm.foodCode = data.foodCode
      this.dataForm.drugCode = data.drugCode
      this.dataForm.licenseCode = data.licenseCode
      this.dataForm.hygieneCode = data.hygieneCode
      this.dataForm.important = data.important
      this.dataForm.address = data.address
      this.dataForm.remark = data.remark
      this.dataForm.imgUrl = data.imgUrl
      this.dataForm.trinity = data.trinity
      this.dataForm.gasNum = data.gasNum
      this.dataForm.fireNum = data.fireNum
      this.dataForm.level = data.level
      this.dataForm.safetyDirectorMobile = data.safetyDirectorMobile
      this.dataForm.safetyDirectorName = data.safetyDirectorName
      this.dataForm.importantType = data.importantType
      this.dataForm.safetyPeril = data.safetyPeril
      this.dataForm.areas = data.areas
      this.dataForm.competentUnit = data.competentUnit
      this.dataForm.isScaleCompany = data.isScaleCompany
      this.dataForm.isHazardousGoodsCompany = data.isHazardousGoodsCompany
      this.dataForm.purpose = data.purpose
      this.dataForm.code = data.code
      this.dataForm.level = data.level
      this.dataForm.content = data.content
      this.dataForm.enterpriseId = data.enterpriseId
      if (data.imgUrl) {
        // this.fileList = [{id: 0, name: '', url: data.imgUrl, fileType: 1}]
        data.imgUrl.split(',').map((file, index) => {
          this.fileList.push({id: index + 1, name: '', url: file, fileType: 1})
        })
      }
      // 场所标签
      if (data.labelList && data.labelList.length > 0) {
        let labels = []
        data.labelList.forEach(item => {
          labels.push(item.path.replace(/^(\s|,)+|(\s|,)+$/g, '').split(',').map(Number))
        })
        this.dataForm.labels = labels
      }
      // 场所自定义字段
      this.$refs.fieldView.init(data.id, 4, data.community || data.street)
      let ancillaryInfo = data.ancillaryInfo || {}
      this.dataForm.extraInfo = {
        id: ancillaryInfo.id,
        address: ancillaryInfo.registerAddr, // 注册地址
        amount: ancillaryInfo.registerCapital, // 开办资金
        operateTime: [ancillaryInfo.operatStart, ancillaryInfo.operatEnd], // 经营期限
        registerTime: ancillaryInfo.registerDate, // 注册日期
        registerCode: ancillaryInfo.registerId, // 注册登记号
        moneySource: ancillaryInfo.economicSource, // 经济来源
        moneySourceList: [{value: 1, label: '自有资金'}, {value: 2, label: '银行借贷资金'}, {value: 3, label: '预售款资金'}], // 经济来源下拉
        officeName: ancillaryInfo.registerAuthority, // 登记机关名称
        payer: ancillaryInfo.taxpayer, // 纳税人名称
        payerCode: ancillaryInfo.taxpayerId, // 纳税人识别号
        payCheck: ancillaryInfo.taxRegistrationDate, // 税务登记日期
        payOffice: ancillaryInfo.taxRegistrationAuthority, // 税务登记机关
        payerStatus: ancillaryInfo.taxpayerStatus, // 纳税人状态
        payerStatusList: [{value: 0, label: '正常'}, {value: 1, label: '非正常'}], // 纳税人状态下拉
        payCancel: ancillaryInfo.taxCancellationDate, // 税务注销日期
        insuredName: ancillaryInfo.insuredEntity, // 参保单位名称
        insuredCode: ancillaryInfo.insuredEntityId, // 参保单位编号
        riskLevel: ancillaryInfo.industryRiskType, // 社保行业风险类别
        insuredStage: ancillaryInfo.insurancePaymentDate, // 参保缴费数据期别
        insuredStatus: ancillaryInfo.insurancePaymentStatus, // 参保缴费状态
        insuredStatusList: [{value: 1, label: '参保缴费'}, {value: 2, label: '暂停缴费'}, {value: 3, label: '冻结'}], // 参保缴费状态下拉
        manageOffice: ancillaryInfo.insuranceAuthority, // 保险管理机关
        signCancelTime: ancillaryInfo.cancellationDate, // 注销登记日期
        signCancelReason: ancillaryInfo.cancellationCause, // 注销/吊销原因
      }
    },
    cardIdIdentified(e) {
      let formdata = new FormData();
      formdata.append("file", e.file)
      this.$http({
        url: this.$http.adornUrl('/wxapp/home/file/ocr'),
        method: 'post',
        headers: {
          appid: 'wxcd8dbbf3f2ba3b14',
          type: 2
        },
        data: formdata
      }).then(({data}) => {
        if (data.code == 0) {
          this.dataForm.licenseCode = data.data.result
          this.getInfoByLicenseCode()
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    showlabel() {
      if (!this.disable) {
        this.label = true
      }
    },
    labelOverlay() {
      let temporaryLabel = this.temporaryLabel[0]
      if (!temporaryLabel) {
        return
      }
      for (let i in that.labels) {
        let label = that.labels[i]
        if (label.value == temporaryLabel.value) {
          return
        }
      }
      temporaryLabel["delete"] = false
      this.labels.push(temporaryLabel)
      this.dataForm.labels.push(temporaryLabel.ids)
    },
    labelClose(e) {
      this.label = false
    },
    labelFinish(e) {
      let value = []
      e.selectedOptions.map(e => value.push(e.value))
      let map = {}
      map["value"] = value
      map['options'] = e.selectedOptions
      let temporaryLabel = formatLabel(map, false)
      this.temporaryLabel = temporaryLabel
    },
    removeLabel(value) {
      for (let i in this.dataForm.labels) {
        let label = this.dataForm.labels[i] + ''
        let labelArray = label.split(",")
        let id = labelArray[labelArray.length - 1]
        if (id == value) {
          this.dataForm.labels.splice(i, 1)
        }
      }

      for (let i in this.labels) {
        let label = this.labels[i]
        if (label.value == value) {
          this.labels[i]["delete"] = true
        }
      }
      this.dataForm.labels = this.dataForm.labels
      this.labels = this.labels
      this.$forceUpdate()
    },
    // 经营类型选择
    typeConfirm({ selectedOptions }) {
      let value = selectedOptions[selectedOptions.length - 1];
      if (value) {
        this.selectMsg.placeTypeStr = value.label;
        this.dataForm.type = value.value;
      }
      if (!value.children || value.children.length == 0) {
        this.typeShow = false;
      }
    },
    // 所属小区选择
    subareaConfirm(value) {
      if (value) {
        this.selectMsg.buildingStr = '请选择'
        this.dataForm.buildingId = ''
        this.list.buildingList = []
        this.selectMsg.subareaStr = value.label;
        if (this.street == this.initOrgId) {
          this.dataForm.community = value.value;
        } else {
          this.dataForm.subarea = value.value;
        }
      }
      this.subareaShow = false;
      this.getBuildingList(value.value)
    },
    // 详细地址选择
    buildingFinish(value) {
      let options = value.selectedOptions
      this.selectMsg.buildingStr = '请选择'
      this.dataForm.buildingId = ''
      let option = options[options.length - 1];
      if (option.depth !== 1 && option.depth !== 3) {
        let map = {}
        map["value"] = option.value
        map['label'] = option.label
        this.temporaryBuilding = map
        let type = option.depth == 2 ? 1 : 2
        this.dataForm.buildingDepth = option.depth
        this.getCodeById(option.value, type)
      }
    },
    buildingOverlay() {
      if (this.temporaryBuilding && this.temporaryBuilding.value !== '') {
        this.selectMsg.buildingStr = this.temporaryBuilding.label
        this.dataForm.buildingId = this.temporaryBuilding.value
      }
    },
    // 所属楼幢选择
    buildingConfirm(value) {
      if (value) {
        this.selectMsg.buildingStr = value.label;
        this.dataForm.buildingId = value.value;
      }
      this.buildingShow = false;
    },
    // 店铺类型
    categoryConfirm (value) {
      if (value) {
        this.selectMsg.categoryStr = value.label;
        this.dataForm.category = value.value;
      }
      this.categoryShow = false;
    },
    importantTypeConfirm(value) {
      if (value) {
        this.selectMsg.importantTypeStr = value.label;
        this.dataForm.importantType = value.value;
      }
      this.importantTypeShow = false;
    },
    operateStatusConfirm(value) {
      if (value) {
        this.selectMsg.operateStatusStr = value.label;
        this.dataForm.shopOperateStatus = value.value;
      }
      this.operateStatusShow = false;
    },
    safetyPerilConfirm (value) {
      if (value) {
        this.selectMsg.safetyPerilStr = value.label;
        this.dataForm.safetyPeril = value.value;
      }
      this.safetyPerilShow = false;
    },
    hasHygieneConfirm (value) {
      if (value) {
        this.selectMsg.hasHygieneStr = value.label;
        this.dataForm.hasHygiene = value.value;
      }
      this.hasHygieneShow = false;
    },
    hasFoodConfirm (value) {
      if (value) {
        this.selectMsg.hasFoodStr = value.label;
        this.dataForm.hasFood = value.value;
      }
      this.hasFoodShow = false;
    },
    hasMedicalConfirm (value) {
      if (value) {
        this.selectMsg.hasMedicalStr = value.label;
        this.dataForm.hasMedical = value.value;
      }
      this.hasMedicalShow = false;
    },
    hasDrugConfirm (value) {
      if (value) {
        this.selectMsg.hasDrugStr = value.label;
        this.dataForm.hasDrug = value.value;
      }
      this.hasDrugShow = false;
    },
    operateBeginConfim (value) {
      let d = new Date(this.extraInfo.operateEndTime)
      if (this.extraInfo.operateEndTime != '' && d.getTime(d) < value.getTime(value)) {
        return this.$toast.fail('开始时间不能比结束时间大哦')
      }
      this.extraInfo.operateBeginTime = formatterDate(value)
      this.selectMsg.operateBeginTime = formatterDate(value)
      this.operateBeginShow = false
    },
    operateEndConfim (value) {
      let d = new Date(this.extraInfo.operateBeginTime)
      if (this.extraInfo.operateBeginTime != '' && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail('结束时间不能比开始时间小哦')
      }
      this.extraInfo.operateEndTime = formatterDate(value)
      this.selectMsg.operateEndTime = formatterDate(value)
      this.operateEndShow = false
    },
    registerConfim (value) {
      this.extraInfo.registerTime = formatterDate(value)
      this.selectMsg.registerTime = formatterDate(value)
      this.registerShow = false
    },
    moneySourceConfirm (value) {
      if (value) {
        this.selectMsg.moneySourceStr = value.label;
        this.extraInfo.moneySource = value.value;
      }
      this.moneySourceShow = false;
    },
    payCheckConfim (value) {
      this.extraInfo.payCheck = formatterDate(value)
      this.selectMsg.payCheck = formatterDate(value)
      this.payCheckShow = false
    },
    payerStatusConfirm (value) {
      if (value) {
        this.selectMsg.payerStatusStr = value.label;
        this.extraInfo.payerStatus = value.value;
      }
      this.payerStatusShow = false;
    },
    payCancelConfim (value) {
      this.extraInfo.payCancel = formatterDate(value)
      this.selectMsg.payCancel = formatterDate(value)
      this.payCancelShow = false
    },
    signCancelConfim (value) {
      this.extraInfo.signCancelTime = formatterDate(value)
      this.selectMsg.signCancelTime = formatterDate(value)
      this.signCancelShow = false
    },
    riskLevelConfirm (value) {
      if (value) {
        this.selectMsg.riskLevel = value.label;
        this.extraInfo.riskLevel = value.value;
      }
      this.riskLevelShow = false;
    },
    insuredStageConfim (value) {
      this.extraInfo.insuredStage = formatterDate(value)
      this.selectMsg.insuredStage = formatterDate(value)
      this.insuredStageShow = false
    },
    insuredStatusConfirm (value) {
      if (value) {
        this.selectMsg.insuredStatus = value.label;
        this.extraInfo.insuredStatus = value.value;
      }
      this.insuredStatusShow = false;
    },
    addInfo () {
      let addInfo = {
        id: 0,
        changeDate: '',
        changeReason: '',
        beforeContent: '',
        afterContent: '',
        status: 0,
        changeDateShow: false
      }
      this.changeList.push(addInfo)
      this.submitList = this.changeList
    },
    deleteClick (id, index) {
      let change = this.changeList
      change.map(item => {
        if (item.id == id) {
          item.status = 1
        }
      })
      this.changeList = this.changeList.filter((item, idx) => { return index !== idx })
      this.submitList = change
    },
    changeDateConfim (value, idx){
      let change = this.changeList
      change.map((item, index) => {
        if (index == idx) {
          item.changeDate = formatterDate(value)
          item.changeDateShow = false
        }
      })
      this.changeList = change
      this.submitList = change
    },
    // 网格选择
    selectGrid (e) {
      this.dataForm.gridId = 0
      this.dataForm.miniGridId = 0
      const {selectList, labelCondition, depth} = e
      this.selectMsg.gridName = labelCondition[0].label;
      this.dataForm.gridId = e.labelCondition[0].fatherValue || parseInt(selectList)
      this.dataForm.miniGridId = e.labelCondition[0].fatherValue ? parseInt(selectList) : 0
      this.gridShow = false
    },
    codeConfirm (value){
      if (value) {
        this.selectMsg.codeStr = value.value;
        this.dataForm.code = value.value;
        this.selectMsg.codeName = value.label
        this.dataForm.address = value.label
        this.list.codeList.map(item => {
          if (item.value == value.value) {
            this.codeMappingId = item.codeMappingId
          }
        })
      }
      this.codeShow = false;
    },
    getCode() {
      this.list.codeList = []
      this.selectMsg.codeStr = '请选择'
      this.dataForm.code = ''
      this.isTipShow = false
      this.tipValue = ''
      let url = '/wxapp/sys/addressCodeMapping/listByName'
      let name = true
      if (isNumber(this.selectMsg.codeName)) {
        url = '/wxapp/sys/addressCodeMapping/infoByCode'
        name = false
      }
      this.$http({
        url: this.$http.adornUrl(url),
        method: 'post',
        params: this.$http.adornParams({
          name: name ? this.selectMsg.codeName : undefined,
          code: name ? undefined : this.selectMsg.codeName,
          type: 4
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          let list = (data.list || data.sysAddressCodeMapping).map(item => {
            return {
              value: item.houseCode ? item.houseCode : item.doorCode,
              label: item.fullName,
              codeMappingId: item.id
            }
          })
          if (name) {
            this.list.codeList = list
          } else {
            this.list.codeList = list
          }
        this.codeShow = true
        } else {
          this.isTipShow = true
          this.tipValue = data.msg
          // this.$toast.fail(data.msg)
        }
      })
    },
    getCodeById (id, type) {
      // 1 楼幢, 2 房屋
      this.$http({
        url: this.$http.adornUrl(`/wxapp/sys/addressCodeMapping/infoByIdAndType`),
        method: 'get',
        params: this.$http.adornParams({
          id: id,
          type: type
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          if (data.one) {
            if (type == 1) {
              this.dataForm.code = data.one.buildingCode
              this.selectMsg.codeStr = data.one.buildingCode
            } else {
              this.dataForm.code = data.one.houseCode
              this.selectMsg.codeStr = data.one.houseCode
            }
            this.selectMsg.codeName = data.one.fullName
            this.codeMappingId = data.one.id
          }
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 删除图片
    uploaddel(file, detail) {
      //debugger;
      let imgUrls = this.dataForm.imgUrl.split(",");
      imgUrls.splice(detail, 1);
      this.dataForm.imgUrl = imgUrls.join();
    },
    // 上传图片
    uploadRead(file) {
      let that = this;
      this.$toast.loading({
        message: "上传中...",
        forbidClick: true,
        duration: 0,
        overlay: true,
      });
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
      let formdata = new FormData();
      formdata.append("file", item.file);
      formdata.append("path", `files/place/${this.$orgId}`);
      this.$http({
        url: this.$http.adornUrl("/wxapp/file/upload"),
        method: "post",
        data: formdata,
      }).then(({ data }) => {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
        if (data.code == 0) {
          console.log(this.urls);
          // let uid = that.urls.length + 1;
          // let u = getImageStream(data.fileMessage.relativePath);
          // let url = {
          //   uid: uid,
          //   status: "done",
          //   url: u,
          // };
          // that.urls.push(url);
          that.dataForm.imgUrl = that.dataForm.imgUrl
            ? that.dataForm.imgUrl + "," + data.fileMessage.relativePath
            : data.fileMessage.relativePath;

          // this.dataForm.imgUrl += ','+data.fileMessage.relativePath;

          // this.dataForm.newFileMessages.push(data.fileMessage);
          return true;
        } else {
          that.$toast.fail(data.msg);
        }
      });
      })
    },
    async submit() {
      let Add=true
      let url = "/wxapp/place/placeinfostreetshop/save";
      if (this.dataForm.id && this.dataForm.id != 0) {
        Add=false
        url = "/wxapp/place/placeinfostreetshop/update";
      }
      let reg=/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if(this.dataForm.contactMobile.length==8||(this.dataForm.contactMobile.length==12&&this.dataForm.contactMobile.substring(0,1)==0)||reg.test(this.dataForm.contactMobile)){
      }
      else{
          return this.$toast.fail('失败:联系人电话格式有误')

      }
      if (this.dataForm.subarea === '' && this.dataForm.community === '') {return this.$toast.fail('失败:请选择小区');}
      if (this.dataForm.name === '') {return this.$toast.fail('失败:店铺名称不能为空');}
      if (this.dataForm.type === '') {return this.$toast.fail('失败:经营类型不能为空');}
      if (this.dataForm.category === '') {return this.$toast.fail('失败:店铺类型不能为空');}
      if (this.dataForm.importantType === '') {return this.$toast.fail('失败:重点属性不能为空');}
      if (this.dataForm.address === '') {return this.$toast.fail('失败:详细地址不能为空');}
      if (this.dataForm.contact === '') {return this.$toast.fail('失败:主要联系人不能为空');}
      if (this.dataForm.contactMobile === '') {return this.$toast.fail('失败:联系人电话不能为空');}
      // 自定义字段参数
      let extendInfoList = await this.$refs.fieldView.submitForm()

      let buildingDepth = null
      if (this.dataForm.buildingDepth == 2) {
        buildingDepth = 1
      } else if (this.dataForm.buildingDepth == 4) {
        buildingDepth = 3
      }
      this.dataForm.buildingDepth = buildingDepth

      let data = {
        ...this.dataForm,
        shopType: this.dataForm.category
      }
      this.buttonLoading = true
      this.$http({
        url: this.$http.adornUrl(url),
        method: "post",
        data: {...data, extendInfoList, labels: this.dataForm.labels.join(','),codeMappingId: this.codeMappingId || undefined},
      }).then(({ data }) => {
        if (data.code == 0) {
          this.dataForm.id = data.id
          let placeAncillaryInfo = {
            recordId: this.dataForm.id,
            recordType: 1,
            registerAddr: this.extraInfo.address,
            registerCapital: this.extraInfo.amount,
            operatStart: this.extraInfo.operateBeginTime,
            operatEnd: this.extraInfo.operateEndTime,
            registerDate: this.extraInfo.registerTime,
            registerId: this.extraInfo.registerCode,
            economicSource: this.extraInfo.moneySource,
            registerAuthority: this.extraInfo.officeName,
            taxpayer: this.extraInfo.payer,
            taxpayerId: this.extraInfo.payerCode,
            taxRegistrationDate: this.extraInfo.payCheck,
            taxRegistrationAuthority: this.extraInfo.payOffice,
            taxpayerStatus: this.extraInfo.payerStatus,
            taxCancellationDate: this.extraInfo.payCancel,
            insuredEntity: this.extraInfo.insuredName,
            insuredEntityId: this.extraInfo.insuredCode,
            industryRiskType: this.extraInfo.riskLevel,
            insurancePaymentDate: this.extraInfo.insuredStage,
            insurancePaymentStatus: this.extraInfo.insuredStatus,
            insuranceAuthority: this.extraInfo.manageOffice,
            cancellationDate: this.extraInfo.signCancelTime,
            cancellationCause: this.extraInfo.signCancelReason,
          }
          //此处要添加orgid判断   this.dataForm.orgId翠苑街道
          if (Add == true&& (this.street==16724||this.$orgId==16724)) {
            Dialog.confirm({
              title: '提示',
              message: '是否发送短信？',
            })
                .then(() => {
                  this.$http({
                    url: this.$http.adornUrl('/noteurl/converShortUrl'),
                    method: 'get',
                    params: this.$http.adornParams({
                      //更改url
                      longUrl: `${this.urlCY}/proLogin?id=${this.dataForm.id}&Mobile=${this.dataForm.contactMobile}`
                    })
                  }).then(({data}) => {
                    if (data && data.code === 0) {
                      this.$http({
                        url: this.$http.adornUrl(`/wxapp/sendNote/openmas/sendSms`),
                        method: 'get',
                        params: this.$http.adornParams({
                          ids: this.dataForm.id,
                          num: 2,
                          isAll: 0,
                          sendNoteContent: `尊敬的经营户朋友，您好！为营造最优法治营商环境，携手共创“富裕”翠苑，您可登录“翠苑小店通”${data.shortUrl}了解日常经营规范、预约服务检查，若您在经营过程中有其他诉求或政策需要咨询，也可通过“翠苑小店通”进行反馈，感谢您的支持！`,
                          sendNoteSource: 7,
                          smsSendType: 1,
                        })
                      }).then(({data}) => {
                        if (data && data.code === 0 ) {
                          // this.$toast.success({
                          //   message: '发送成功',
                          //   duration: 2000,
                          // })
                          this.$toast.success({
                            message: '操作成功',
                            duration: 2000,
                            onClose: () => {
                              this.$http({
                                url: this.$http.adornUrl('/wxapp/place/placeancillaryinfo/save'),
                                method: 'post',
                                data: {...placeAncillaryInfo},
                              }).then(({data}) => {
                                if (data && data.code === 0) {
                                  let submitList = this.submitList.map(item => {
                                    return {
                                      recordId: this.dataForm.id || undefined,
                                      recordType: 1,
                                      changeDate: item.changeDate,
                                      changeEvent: item.changeReason,
                                      oldContent: item.beforeContent,
                                      newContent: item.afterContent,
                                      status: item.status
                                    }
                                  })
                                  this.$toast.success({
                                    message: '操作成功',
                                    duration: 2000,
                                    onClose: () => {
                                      if (submitList.length > 0) {
                                        this.$http({
                                          url: this.$http.adornUrl('/wxapp/place/placeinfochange/save'),
                                          method: 'post',
                                          data: this.$http.adornData(submitList, false)
                                        }).then(({data}) => {
                                          if (data && data.code === 0) {
                                            this.buttonLoading = false
                                            this.$toast.success({
                                              message: '操作成功',
                                              duration: 2000,
                                              onOpened: () => {
                                                this.$router.go(-1)
                                              }
                                            })
                                          } else {
                                            this.buttonLoading = false
                                            this.$toast.fail(data.msg)
                                          }
                                        })
                                      } else {
                                        this.buttonLoading = false
                                        this.$router.go(-1)
                                      }
                                    }
                                  })
                                } else {
                                  this.$toast.fail(data.msg)
                                }
                              })
                            }
                          })
                        }

                      })
                    } else {
                      this.buttonLoading = false
                      this.$toast.fail(data.msg);
                    }
                  })

                })
                .catch(() => {
                  // this.$toast.fail('取消发送');
                  this.$toast.success({
                    message: '取消发送',
                    duration: 2000,
                    onClose: () => {
                      this.$http({
                        url: this.$http.adornUrl('/wxapp/place/placeancillaryinfo/save'),
                        method: 'post',
                        data: {...placeAncillaryInfo},
                      }).then(({data}) => {
                        if (data && data.code === 0) {
                          let submitList = this.submitList.map(item => {
                            return {
                              recordId: this.dataForm.id || undefined,
                              recordType: 1,
                              changeDate: item.changeDate,
                              changeEvent: item.changeReason,
                              oldContent: item.beforeContent,
                              newContent: item.afterContent,
                              status: item.status
                            }
                          })
                          this.$toast.success({
                            message: '操作成功',
                            duration: 2000,
                            onClose: () => {
                              if (submitList.length > 0) {
                                this.$http({
                                  url: this.$http.adornUrl('/wxapp/place/placeinfochange/save'),
                                  method: 'post',
                                  data: this.$http.adornData(submitList, false)
                                }).then(({data}) => {
                                  if (data && data.code === 0) {
                                    this.buttonLoading = false
                                    this.$toast.success({
                                      message: '操作成功',
                                      duration: 2000,
                                      onOpened: () => {
                                        this.$router.go(-1)
                                      }
                                    })
                                  } else {
                                    this.buttonLoading = false
                                    this.$toast.fail(data.msg)
                                  }
                                })
                              } else {
                                this.buttonLoading = false
                                this.$router.go(-1)
                              }
                            }
                          })
                        } else {
                          this.$toast.fail(data.msg)
                        }
                      })
                    }
                  })
                });
          }
         else this.$toast.success({
            message: '操作成功',
            duration: 2000,
            onClose: () => {
              this.$http({
                url: this.$http.adornUrl('/wxapp/place/placeancillaryinfo/save'),
                method: 'post',
                data: {...placeAncillaryInfo},
              }).then(({data}) => {
                if (data && data.code === 0) {
                  let submitList = this.submitList.map(item => {
                    return {
                      recordId: this.dataForm.id || undefined,
                      recordType: 1,
                      changeDate: item.changeDate,
                      changeEvent: item.changeReason,
                      oldContent: item.beforeContent,
                      newContent: item.afterContent,
                      status: item.status
                    }
                  })
                  this.$toast.success({
                    message: '操作成功',
                    duration: 2000,
                    onClose: () => {
                      if (submitList.length > 0) {
                        this.$http({
                          url: this.$http.adornUrl('/wxapp/place/placeinfochange/save'),
                          method: 'post',
                          data: this.$http.adornData(submitList, false)
                        }).then(({data}) => {
                          if (data && data.code === 0) {
                            this.buttonLoading = false
                            this.$toast.success({
                              message: '操作成功',
                              duration: 2000,
                              onOpened: () => {
                                this.$router.go(-1)
                              }
                            })
                          } else {
                            this.buttonLoading = false
                            this.$toast.fail(data.msg)
                          }
                        })
                      } else {
                        this.buttonLoading = false
                        this.$router.go(-1)
                      }
                    }
                  })
                } else {
                  this.$toast.fail(data.msg)
                }
              })
            }
          })
        } else {
          this.$toast.fail(data.msg);
        }
      });
    },
  },
};
</script>
<style scoped>
.info >>> .van-radio-group {
  float: unset !important;
}
</style>
<style lang="scss" scoped>
.button-right {
  float: right;
  position: absolute;
  right: 20px;
  top: 5px;
  .van-button {
    padding: 2px 24px;
    background: transparent;
    border-radius: 8px;
    border: 1px solid #4581F8;
    color: #4581F8;
  }
}
.vanTag {
  margin: 0 0 5px 5px;
  border-radius: 5px;
  border: 1px solid #007AFF;
  background: rgba(0, 122, 255, 0.1);
  //padding-right: 20px;
  float: left;

  span {
    display: inline-block;
    padding: 0 17px;
    //margin-right: 20px;
    font-size: 24px;
    color: #007AFF;
    height: 56px;
    line-height: 56px;
    border-right: 1px solid #007AFF;
  }
}
.licenseCode {
  ::v-deep .van-cell__value {
    line-height: 15px;
  }
  ::v-deep .van-field__right-icon {
    transform: translateY(6px);
  }
}
</style>
